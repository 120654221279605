<template>
  <div>
    <div class="container">
      <div class="example">
        <a-spin :spinning="loading">
          <a-row type="flex">
            <a-col :md="4"  class="right scroller">
              <!--组织树-->
              <a-tree
                :default-expand-all="true"
                :tree-data="treeData"
                :selectedKeys.sync="currentNode"
                :key="treeDataKey"
                :replaceFields="{children:'children', title:'deptNameView', key:'key' }"
                @select="onSelect"
              >
              </a-tree>
            </a-col>
            <a-col :md="20">
              <!--组织表格-->
              <user-table
                v-if="currentNode.length>0"
                :current-id="currentNode[0]+''"
                ref="table"
                @ok="handleDeptTree" />
            </a-col>
          </a-row>
        </a-spin>
      </div>
    </div>
  </div>
</template>

<script>
import UserTable from './components/acDeptIndex'
import { deptTree } from '@/api/user-rights/organizemanage'

export default {
  name: 'Index',
  components: {
    UserTable
  },
  data () {
    return {
      // 树
      treeData: [{ deptNameView: '全部', key: 0, children: null }],
      // 选中的节点
      currentNode: [],
      // 缓存选中
      cookieCurrent: [],
      loading: false,
      treeDataKey: 1
    }
  },
  mounted () {
    this.handleDeptTree()
  },
  methods: {
    /**
     * taidi
     * 获取组织树信息
     */
    handleDeptTree () {
      this.loading = true
      deptTree({ parentId: 0 }).then((res) => {
        if (res['code'] === 200) {
          this.treeData[0].children = res.data
          // 默认选择第一个
          this.currentNode[0] = 0
          // 缓存
          this.cookieCurrent[0] = 0
          this.treeDataKey++
        }
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     *选择组织
     */
    onSelect () {
      // 第二次选择为空，将缓存给它
      if (this.currentNode.length < 1) {
        this.currentNode = this.cookieCurrent
      } else {
        this.cookieCurrent[0] = JSON.parse(JSON.stringify(this.currentNode[0]))
      }
      // 下一个生命周期
      this.$nextTick(() => {
        this.refreshTable()
      })
    },
    /**
     * 刷新表格 currentNode部门key===部门id
     * @param
     */
    refreshTable () {
      this.$refs['table'].deptRefresh()
    }
  }
}
</script>

<style lang="less" scoped>

.container {
  background: #fff;
  padding: 5px;
}

.right {
  border-right: 1px solid #e8e8e8;
}
</style>
